<template>
  <div class="modify-phone">
    <div class="report-title-box">修改绑定手机号</div>
    <div class="divider"></div>
    <div class="form-box">
      <div class="form-phone" v-show="type == 1">
        <el-form ref="dataPO" :model="dataPO" :rules="rules">
          <el-form-item prop="phone" label="当前手机号">
            <el-input disabled v-model="dataPO.phone"></el-input>
          </el-form-item>
          <el-form-item prop="code" label="验证码">
            <div class="code-form-box">
              <el-input
                class="code-form"
                v-model="dataPO.code"
                placeholder="请输入验证码"
              ></el-input>
              <div class="get-code" @click.prevent="getCode">
                {{ codeText }}
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-phone" v-show="type == 2">
        <el-form ref="dataPONewPhone" :model="dataPONewPhone" :rules="rulesNewPhone">
          <el-form-item prop="phone" label="新手机号">
            <el-input
              v-model="dataPONewPhone.phone"
              placeholder="请输入新手机号"
              oninput="value=value.replace(/[^\d]/g,'')"
              @blur="dataPONewPhone.phone = $event.target.value"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code" label="验证码">
            <div class="code-form-box">
              <el-input
                class="code-form"
                v-model="dataPONewPhone.code"
                placeholder="请输入验证码"
              ></el-input>
              <div class="get-code" @click.prevent="getNewCode">
                {{ newCodeText }}
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="button-box">
        <el-button type="primary" v-show="type == 1" @click="toNext">下一步</el-button>
        <el-button type="primary" v-show="type == 2" @click="confirmEdit">
          确认修改
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      const reg =
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
      if (new RegExp(reg).test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    };
    return {
      type: 1, //1原手机号 2 新手机号
      dataPO: {
        phone: "",
        code: "",
      },
      dataPONewPhone: {
        phone: "",
        code: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rulesNewPhone: {
        phone: [
          {
            required: true,
            message: "请输入新手机号",
            trigger: "blur",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      codeText: "获取验证码",
      newCodeText: "获取验证码",
    };
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    // 获取用户信息
    getInfo() {
      this.$api.personalCenter
        .getInfo()
        .then((res) => {
          let user = res.data;
          this.dataPO.phone = user.phone;
          this.consumerId = res.data.consumerId;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 当前手机号获取验证码
    getCode() {
      if (this.codeText !== "获取验证码") {
        return;
      }
      this.$refs.dataPO.validateField("phone", (errorMessage) => {
        if (!errorMessage) {
          this.$api.user
            .getVerificationCode({ phone: this.dataPO.phone })
            .then(() => {
              this.$message.success("已发送，请注意查收");
              let maxNum = 59;
              let oldCodeText = this.codeText;
              this.codeText = `${maxNum + 1}s重新发送`; //初始显示倒计时
              let codeCountDown = setInterval(() => {
                let countDownNum = maxNum--;
                this.codeText = `${countDownNum}s重新发送`;
                if (countDownNum == 0) {
                  //倒计时结束
                  this.codeText = oldCodeText;
                  clearInterval(codeCountDown);
                }
              }, 1000);
            })
            .catch((err) => {
              this.$message.error(err?.msg);
            });
        }
      });
    },
    // 新手机号获取验证码
    getNewCode() {
      if (this.newCodeText !== "获取验证码") {
        return;
      }
      this.$refs.dataPONewPhone.validateField("phone", (errorMessage) => {
        if (!errorMessage) {
          this.$api.user
            .getVerificationCode({ phone: this.dataPONewPhone.phone })
            .then(() => {
              this.$message.success("已发送，请注意查收");
              let maxNum = 59;
              let oldCodeText = this.newCodeText;
              this.newCodeText = `${maxNum + 1}s重新发送`; //初始显示倒计时
              let codeCountDown = setInterval(() => {
                let countDownNum = maxNum--;
                this.newCodeText = `${countDownNum}s重新发送`;
                if (countDownNum == 0) {
                  //倒计时结束
                  this.newCodeText = oldCodeText;
                  clearInterval(codeCountDown);
                }
              }, 1000);
            })
            .catch((err) => {
              this.$message.error(err?.msg);
            });
        }
      });
    },
    // 下一步
    toNext() {
      this.$refs.dataPO.validate((valid) => {
        if (valid) {
          this.$api.personalCenter
            .judgePhone({
              code: this.dataPO.code,
              oldPhone: this.dataPO.phone,
              // newPhone: this.dataPO.dataPONewPhone
            })
            .then((res) => {
              this.type = 2;
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        } else {
          return false;
        }
      });
    },
    // 确认修改
    confirmEdit() {
      this.$refs.dataPONewPhone.validate((valid) => {
        if (valid) {
          // this.$api.personalCenter
          //   .unbundling({ childConsumerId: this.consumerId })
          //   .then(res => {
          this.$api.personalCenter
            .updatePhone({
              code: this.dataPONewPhone.code,
              oldPhone: this.dataPO.phone,
              newPhone: this.dataPONewPhone.phone,
            })
            .then(() => {
              this.$message.success("修改手机号成功");
              if (this.$route.query.flag == "modify") {
                setTimeout(() => {
                  this.$router.push("/user/my-center/modify-data");
                }, 500);
              } else {
                setTimeout(() => {
                  this.$router.push("/user/my-center/modify-data");
                }, 500);
              }
            })
            .catch((err) => {
              this.$message.error(err?.msg);
            });
          // });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
